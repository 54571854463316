@import '_variables'

.footerBackgroundColor
    background-color: $lightGreyColor
    padding: 35px $OffsetMargin
    margin-top: $sectionMargin
    
.footer
    margin-bottom: 30px
    display: flex
    flex-wrap: wrap
    gap: 2%
    justify-content: center
    .socialMedia
        display: flex
        align-items: center
        gap: 15px
        img
            height: 24px
            aspect-ratio: 1/1
        a
            margin-bottom: 0px !important
    .col
        flex: 1
        h3
            margin-bottom: 18px
        a
            width: fit-content
            display: block
            color: $darkGreyColor
            &:hover
                color: $HoverBackgroundColor
        div, p
            margin-bottom: 18px
        .logo
            font-size: 1.2rem
            font-weight: 700
            div
                margin-top: 15px
            span
                font-weight: 300
                font-size: .8rem
.contactItem
    display: flex
    align-items: center
    gap: 10px
    .contact
        font-weight: 700
        letter-spacing: .2px

.copyright
    font-size: 1rem
    color: $darkGreyColor

@media screen and (max-width: 900px) 
    .footer 
        gap: 20px
        .col 
            flex-basis: 48% !important

@media screen and (max-width: 700px) 
    .footer
        gap: 20px
        .col 
            flex-basis: 100% !important

            