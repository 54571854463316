@import '_variables'

.contactWrapper
    width: 40%

.contactItem
    display: flex
    align-items: center
    gap: 10px
    margin-top: 18px
    .contact
        font-weight: 700
        letter-spacing: .2px
        display: block
        &:hover
            color: $HoverBackgroundColor