//Colors
$whiteColor: #f5f5f5
$accentColor: #041025
$CardBorderColor: #D1D5DC
$HoverBackgroundColor: #1653CA
$lightGreyColor: #E6E9ED
$darkGreyColor: #676B70
$lightblue: #C3CDDE

//Margins
$OffsetMargin: 35px
$sectionMargin: 80px