@import '_variables'

.subHeading
    margin-top: 60px
    margin-bottom: 20px

.sectionWrapper
    display: flex
    width: 100%
    max-width: 750px
    gap: 20px
    flex-wrap: wrap

.inputWrapper
    flex: 1 1 48%
    .error
        color: red
        font-weight: 400
        font-size: 0.8rem
        margin-top: 10px
    input, select 
        width: 100%
        display: inline-block
        padding: 9px 12px
        margin-top: 10px
        border: 3px solid $CardBorderColor
        background-color: transparent
        &:hover
            background-color: $lightblue
            border-color: $lightblue
            outline: $lightblue
        &:focus-visible
            background-color: $lightblue
            border: 2px solid $accentColor
            outline: $lightblue

    
    select 
        -webkit-appearance: none
        appearance: none
        position: relative
        option
            padding: 8px 0
    .selectArrow
        background-image: linear-gradient(45deg, transparent 50%, $accentColor 50%),  linear-gradient(135deg, $accentColor 50%, transparent 50%)
        background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), 100% 0
        background-size: 5px 5px, 5px 5px, 2.5em 2.5em
        background-repeat: no-repeat
        &:focus-visible, &:hover
            background-image: linear-gradient(45deg, $accentColor 50%, transparent 50%), linear-gradient(135deg, transparent 50%, $accentColor 50%)
            background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em, 100% 0
            background-size: 5px 5px, 5px 5px, 2.5em 2.5em
            outline: 0
    
button
    padding: 16px 64px !important