@import '_variables'

.gallery
    cursor: grab
    div
        width: 100%
        height: 460px
        img
            height: 100%
            width: 100%
            object-fit: contain
            object-position: center

.aboutContentWrapper
    max-width: 1250px
    margin: auto
    margin-top: 35px

@media screen and (max-width: 700px)
    .aboutContentWrapper
        grid-auto-flow: row
        div, p
            grid-column: span 2
        p
            &:last-child
                grid-row: 2 / span 3